const state = () => ({
  upload_document: {
    loan_id: "",
    applicant: {
      nrc_front: "",
      nrc_back: "",
      copy_of_census: "",
      selfie_photo: "",
      employee_card: "",
      company_hr_recommendation_letter: "",
      bill_photo: "",
      ward_recommendation_letter: "",
      original_collateral: "",
      current_home_photo: "",
      type: "applicant-document",
      completed_percent: 0,
    },
    guarantor_family: {
      nrc_front: "",
      nrc_back: "",
      copy_of_census: "",
      selfie_photo: "",
      ward_recommendation_letter: "",
      current_home_photo: "",
      type: "guarantor-family-document",
      completed_percent: 0,
    },
    guarantor_colleague: {
      nrc_front: "",
      nrc_back: "",
      copy_of_census: "",
      selfie_photo: "",
      ward_recommendation_letter: "",
      current_home_photo: "",
      type: "guarantor-colleague-document",
      completed_percent: 0,
    },
  },
});

const getters = {};
const actions = {};
const mutations = {
  updateLoanID: (state, loan_id) => (state.upload_document.loan_id = loan_id),

  documentDetails: (state, responseData) => {
    if (responseData.applicant != null) {
      if (responseData.applicant.nrc_front) {
        state.upload_document.applicant.nrc_front =
          responseData.applicant.nrc_front;
      }

      if (responseData.applicant.nrc_back) {
        state.upload_document.applicant.nrc_back =
          responseData.applicant.nrc_back;
      }

      if (responseData.applicant.copy_of_census) {
        state.upload_document.applicant.copy_of_census =
          responseData.applicant.copy_of_census;
      }

      if (responseData.applicant.selfie_photo) {
        state.upload_document.applicant.selfie_photo =
          responseData.applicant.selfie_photo;
      }

      if (responseData.applicant.employee_card) {
        state.upload_document.applicant.employee_card =
          responseData.applicant.employee_card;
      }

      if (responseData.applicant.company_hr_recommendation_letter) {
        state.upload_document.applicant.company_hr_recommendation_letter =
          responseData.applicant.company_hr_recommendation_letter;
      }

      if (responseData.applicant.bill_photo) {
        state.upload_document.applicant.bill_photo =
          responseData.applicant.bill_photo;
      }

      if (responseData.applicant.ward_recommendation_letter) {
        state.upload_document.applicant.ward_recommendation_letter =
          responseData.applicant.ward_recommendation_letter;
      }

      if (responseData.applicant.original_collateral) {
        state.upload_document.applicant.original_collateral =
          responseData.applicant.original_collateral;
      }

      if (responseData.applicant.current_home_photo) {
        state.upload_document.applicant.current_home_photo =
          responseData.applicant.current_home_photo;
      }

      state.upload_document.applicant.completed_percent =
        responseData.applicant.completed_percent;
    }

    if (responseData.guarantor_family != null) {
      if (responseData.guarantor_family.nrc_front) {
        state.upload_document.guarantor_family.nrc_front =
          responseData.guarantor_family.nrc_front;
      }

      if (responseData.guarantor_family.nrc_back) {
        state.upload_document.guarantor_family.nrc_back =
          responseData.guarantor_family.nrc_back;
      }

      if (responseData.guarantor_family.copy_of_census) {
        state.upload_document.guarantor_family.copy_of_census =
          responseData.guarantor_family.copy_of_census;
      }

      if (responseData.guarantor_family.selfie_photo) {
        state.upload_document.guarantor_family.selfie_photo =
          responseData.guarantor_family.selfie_photo;
      }

      if (responseData.guarantor_family.ward_recommendation_letter) {
        state.upload_document.guarantor_family.ward_recommendation_letter =
          responseData.guarantor_family.ward_recommendation_letter;
      }

      if (responseData.guarantor_family.current_home_photo) {
        state.upload_document.guarantor_family.current_home_photo =
          responseData.guarantor_family.current_home_photo;
      }

      state.upload_document.guarantor_family.completed_percent =
        responseData.guarantor_family.completed_percent;
    }

    if (responseData.guarantor_colleague) {
      if (responseData.guarantor_colleague.nrc_front) {
        state.upload_document.guarantor_colleague.nrc_front =
          responseData.guarantor_colleague.nrc_front;
      }

      if (responseData.guarantor_colleague.nrc_back) {
        state.upload_document.guarantor_colleague.nrc_back =
          responseData.guarantor_colleague.nrc_back;
      }

      if (responseData.guarantor_colleague.copy_of_census) {
        state.upload_document.guarantor_colleague.copy_of_census =
          responseData.guarantor_colleague.copy_of_census;
      }

      if (responseData.guarantor_colleague.selfie_photo) {
        state.upload_document.guarantor_colleague.selfie_photo =
          responseData.guarantor_colleague.selfie_photo;
      }

      if (responseData.guarantor_colleague.ward_recommendation_letter) {
        state.upload_document.guarantor_colleague.ward_recommendation_letter =
          responseData.guarantor_colleague.ward_recommendation_letter;
      }

      if (responseData.guarantor_colleague.current_home_photo) {
        state.upload_document.guarantor_colleague.current_home_photo =
          responseData.guarantor_colleague.current_home_photo;
      }

      state.upload_document.guarantor_colleague.completed_percent =
        responseData.guarantor_colleague.completed_percent;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
