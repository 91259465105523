<template>
  <!-- <common-header :title="title"></common-header> -->
  <div class="header q-pl-sm">
    <q-icon name="west" @click="goBack" style="font-size: 25px" />
    <span class="title q-pl-sm">HP Document</span>
  </div>

  <div class="total_finish">
    <div class="q-py-md text-center text-white custom_font">
      <span>Total Finish</span>
      <br />
      <span>{{ total_finish_percent }}%</span>
    </div>
    <div class="q-pt-sm q-pb-md q-px-lg finish_progress">
      <div>
        <span class="text-white float-right">{{ total_finish_percent }}%</span>
        <q-linear-progress
          rounded
          white
          size="8px"
          :value="total_finish_percent / 100"
        />
      </div>
    </div>
  </div>
  <div class="q-pa-md">
    <div class="q-gutter-md">
      <div>
        <span class="text-negative q-pr-md">*</span>
        <span>Fullfill data to finance needs</span>
      </div>
      <!-- loan_id: {{ this.loan_id }}
            <br />
            token: {{ this.token }} -->
      <div class="spacing row items-start">
        <q-card class="my_card q-mb-md" @click="toApplicantInformation">
          <q-card-section>
            <div>
              <img
                class="image"
                src="../../src/assets/Applicant_Information.jpg"
              />
            </div>
            <div class="text-center">
              <span class="text-green"
                >{{ hpLoan.applicant_information_percent }}%</span
              >
              <q-linear-progress
                rounded
                class="q-mt-sm q-mb-sm mini_progress"
                white
                size="6px"
                :value="hpLoan.applicant_information_percent / 100"
              />
              <span class="custom_size">Applicant Information</span>
            </div>

            <!-- <div class="text-center" v-else>
                        <span class="text-green">0%</span>
                        <q-linear-progress rounded class="q-mt-sm q-mb-sm mini_progress" white size="6px" :value="0" />
                        <span class="custom_size">Applicant Information</span>
                     </div> -->
          </q-card-section>
        </q-card>

        <q-card class="my_card q-mb-md" @click="toOccupationInformation">
          <q-card-section>
            <div>
              <img
                class="image"
                src="../../src/assets/Occupation_Information.jpg"
              />
            </div>
            <div class="text-center">
              <span class="text-green"
                >{{ hpLoan.applicant_occupation_percent }}%</span
              >
              <q-linear-progress
                rounded
                class="q-mt-sm q-mb-sm mini_progress"
                white
                size="6px"
                :value="hpLoan.applicant_occupation_percent / 100"
              />
              <span class="custom_size">Occupation Information</span>
            </div>

            <!-- <div class="text-center" v-else>
                        <span class="text-green">0%</span>
                        <q-linear-progress rounded class="q-mt-sm q-mb-sm mini_progress" white size="6px" :value="0" />
                        <span class="custom_size">Occupation Information</span>
                     </div> -->
          </q-card-section>
        </q-card>

        <q-card class="my_card q-mb-md" @click="toGuarantorFamily">
          <q-card-section>
            <div>
              <img class="image" src="../../src/assets/Gurantar_family.jpg" />
            </div>
            <div class="text-center">
              <span class="text-green"
                >{{ hpLoan.guarantor_family_percent }}%</span
              >
              <q-linear-progress
                rounded
                class="q-mt-sm q-mb-sm mini_progress"
                white
                size="6px"
                :value="hpLoan.guarantor_family_percent / 100"
              />
              <span class="custom_size">Guarantor Family Information</span>
            </div>

            <!-- <div class="text-center" v-else>
                        <span class="text-green">0%</span>
                        <q-linear-progress rounded class="q-mt-sm q-mb-sm mini_progress" white size="6px" :value="0" />
                        <span class="custom_size">Guarantor_Family</span>
                     </div> -->
          </q-card-section>
        </q-card>

        <q-card class="my_card q-mb-md" @click="toGuarantorColleague">
          <q-card-section>
            <div>
              <img class="image" src="../../src/assets/gurantar.jpg" />
            </div>
            <div class="text-center">
              <span class="text-green"
                >{{ hpLoan.guarantor_colleague_percent }}%</span
              >
              <q-linear-progress
                rounded
                class="q-mt-sm q-mb-sm mini_progress"
                white
                size="6px"
                :value="hpLoan.guarantor_colleague_percent / 100"
              />
              <span class="custom_size">Guarantor Colleague Information</span>
            </div>

            <!-- <div class="text-center" v-else>
                        <span class="text-green">0%</span>
                        <q-linear-progress rounded class="q-mt-sm q-mb-sm mini_progress" white size="6px" :value="0" />
                        <span class="custom_size">Guarantor_Colleague</span>
                     </div> -->
          </q-card-section>
        </q-card>

        <q-card class="my_card q-mb-md" @click="toUploadDocument">
          <q-card-section>
            <div>
              <img class="image" src="../../src/assets/Docunment_upload.jpg" />
            </div>
            <div class="text-center">
              <span class="text-green"
                >{{ hpLoan.application_document_percent }}%</span
              >
              <q-linear-progress
                rounded
                class="q-mt-sm q-mb-sm mini_progress"
                white
                size="6px"
                :value="hpLoan.application_document_percent / 100"
              />
              <span class="custom_size">Upload Documents</span>
            </div>

            <!-- <div class="text-center" v-else>
                        <span class="text-green">0%</span>
                        <q-linear-progress rounded class="q-mt-sm q-mb-sm mini_progress" white size="6px" :value="0" />
                        <span class="custom_size">Upload Document</span>
                     </div> -->
          </q-card-section>
        </q-card>
      </div>

      <div>
        <q-checkbox v-model="check_agree" label="" class="custom_checkbox" />
        <span
          >I accept
          <u class="text-blue" @click="goToTermsAndCondition"
            >terms and conditions</u
          >
          of Win Finance</span
        >
      </div>

      <div>
        <q-btn
          @click="apply"
          class="button_color"
          text-color="black"
          label="Apply"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Notify, Loading } from "quasar";
// import CommonHeader from "@/components/Shared/CommonHeader.vue"
export default {
  components: {
    // CommonHeader
  },
  data() {
    return {
      // title: "HP Document",
      total_finish_percent: 0,
      check_agree: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      loan_id: this.$route.query.loan_id,
      token: this.$route.query.token,
      hpLoan: {},
      disabled_route: false,
    };
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "BackHome",
        query: {
          loan_id: this.loan_id,
          token: this.token,
        },
      });
    },
    getHPLoan() {
      Loading.show();
      axios
        .get(`${this.baseUrl}v1/hp-loans/${this.loan_id}`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          this.hpLoan = response.data.data;
          this.total_finish_percent =
            (this.hpLoan.applicant_information_percent +
              this.hpLoan.applicant_occupation_percent +
              this.hpLoan.guarantor_family_percent +
              this.hpLoan.guarantor_colleague_percent +
              this.hpLoan.application_document_percent) /
            5;
          Loading.hide();
        })
        .catch((error) => {
          Loading.hide();
          Notify.create({
            type: "negative",
            message: error.message,
            position: "top",
            timeout: 1500,
          });
        });
    },
    toApplicantInformation() {
      if (this.disabled_route == false) {
        this.$store.commit("applicant/updateLoanID", this.loan_id);
        this.$router.push({
          name: "ApplicantInformation",
          query: {
            loan_id: this.loan_id,
            token: this.token,
            applicant_information_url: this.hpLoan.applicant_information_url,
          },
        });
      } else {
        Notify.create({
          type: "negative",
          position: "top",
          message: "You can't go this page when applying process!",
          timeout: 1500,
        });
      }
    },
    toOccupationInformation() {
      if (this.disabled_route == false) {
        this.$store.commit("occupation/updateLoanID", this.loan_id);
        this.$router.push({
          name: "OccupationInformation",
          query: {
            loan_id: this.loan_id,
            token: this.token,
            applicant_occupation_url: this.hpLoan.applicant_occupation_url,
          },
        });
      } else {
        Notify.create({
          type: "negative",
          position: "top",
          message: "You can't go this page when applying process!",
          timeout: 1500,
        });
      }
    },
    toGuarantorFamily() {
      if (this.disabled_route == false) {
        this.$store.commit("family/updateLoanID", this.loan_id);
        this.$router.push({
          name: "GuarantorFamily",
          query: {
            loan_id: this.loan_id,
            token: this.token,
            guarantor_family_url: this.hpLoan.guarantor_family_url,
          },
        });
      } else {
        Notify.create({
          type: "negative",
          position: "top",
          message: "You can't go this page when applying process!",
          timeout: 1500,
        });
      }
    },
    toGuarantorColleague() {
      if (this.disabled_route == false) {
        this.$store.commit("colleague/updateLoanID", this.loan_id);
        this.$router.push({
          name: "GuarantorColleague",
          query: {
            loan_id: this.loan_id,
            token: this.token,
            guarantor_colleague_url: this.hpLoan.guarantor_colleague_url,
          },
        });
      } else {
        Notify.create({
          type: "negative",
          position: "top",
          message: "You can't go this page when applying process!",
          timeout: 1500,
        });
      }
    },
    toUploadDocument() {
      if (this.disabled_route == false) {
        this.$store.commit("upload/updateLoanID", this.loan_id);
        this.$router.push({
          name: "UploadDocument",
          query: {
            loan_id: this.loan_id,
            token: this.token,
            application_document_url: this.hpLoan.application_document_url,
          },
        });
      } else {
        Notify.create({
          type: "negative",
          position: "top",
          message: "You can't go this page when applying process!",
          timeout: 1500,
        });
      }
    },
    apply() {
      if (this.total_finish_percent != 100) {
        Notify.create({
          type: "negative",
          position: "top",
          message: "Please fill the all data to complete 100%!",
          timeout: 1500,
        });
      } else {
        if (this.check_agree == true) {
          let parameters = {
            stage: "document_applied_by_customer",
          };
          Loading.show();
          axios
            .put(`${this.baseUrl}v1/hp-loans/${this.loan_id}`, parameters, {
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
            })
            .then((response) => {
              this.disabled_route = true;
              this.$router.push({
                name: "SaveApplyInformation",
                query: {
                  loan_id: this.loan_id,
                  token: this.token,
                },
              });
              Notify.create({
                type: "positive",
                position: "top",
                message: response.data.message,
                timeout: 1500,
              });
              Loading.hide();
            })
            .catch((error) => {
              Loading.hide();
              Notify.create({
                type: "negative",
                position: "top",
                message: error.message,
                timeout: 1500,
              });
            });
        } else {
          Notify.create({
            type: "negative",
            position: "top",
            message: "You must agree terms and conditions!",
            timeout: 1500,
          });
        }
      }
    },
    goToTermsAndCondition() {
      location.href =
        "https://winfinance-demo.umgclouds.com/information/terms-and-conditions";
    },
  },
  created() {
    this.getHPLoan();
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  height: 55px;
  position: fixed;
  z-index: 1;
  padding-top: 17px;
  background: linear-gradient(120.26deg, #f4d9a9 35.44%, #cbcbbc 75.7%);
}
.title {
  font-size: 16px;
  font-weight: bold;
}
.total_finish {
  padding-top: 55px;
  background-color: #005198;
}
.custom_font {
  font-size: 16px;
}
.finish_progress {
  background-color: rgba(255, 255, 255, 0.15);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}
.q-linear-progress {
  color: #008000;
  background: #fff;
}
.my_card {
  width: 47%;
  border-radius: 20px;
}
.spacing {
  justify-content: space-between !important;
}
.q-card__section--vert {
  padding: 10px !important;
}
.image {
  width: 100%;
}
.custom_size {
  font-size: 12px;
}
.mini_progress {
  width: 75%;
  margin: 2% 13%;
}
.custom_checkbox {
  margin-left: -10px;
}
.button_color {
  width: 100%;
  height: 50px;
  border-radius: 8px;
  margin-bottom: 60px;
  /* margin-bottom: 120px; */
  background: linear-gradient(120.26deg, #f4d9a9 35.44%, #cbcbbc 75.7%);
}
</style>