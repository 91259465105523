const state = () => ({
  guarantor_family: {
    loan_id: "",
    name: "",
    phone_number: "",
    date_of_birth: "",
    nrc_state_id: "",
    nrc_state_number: "",
    nrc_state_burmese_number: "",
    nrc_district_id: "",
    nrc_district_name: "",
    nrc_district_burmese_name: "",
    nrc_type_id: "",
    nrc_type_name: "",
    nrc_type_burmese_name: "",
    nrc_number: "",
    nationality: "",
    other_nationality: "",
    gender: "",
    marital_status: "",
    // occupation: "UMG",
    // occupation_details: "",
    relationship_with_applicant: "",
    country_state_id: "",
    city_id: "",
    township_id: "",
    place_type: "Ward",
    ward_or_village_name: "",
    street: "",
    building_number: "",
    floor: "",
    residence_type: "",
    living_with: "",
    years_of_stay: "",
    months_of_stay: "",
    company_name: "",
    department_name: "",
    position: "",
    years_of_service: "",
    months_of_service: "",
    monthly_basic_income: 0,
    additional_income: 0,
    total_income: 0,
  },
  company_address: {
    country_state_id: "",
    city_id: "",
    township_id: "",
    place_type: "Ward",
    ward_or_village_name: "",
    street: "",
    building_number: "",
    floor: "",
  },
});

const getters = {
  getGuarantorFamilyData(state) {
    // if (state.guarantor_family.occupation == "Other") {
    //    state.guarantor_family.occupation =
    //       state.guarantor_family.occupation_details;
    // }
    return state.guarantor_family;
  },

  getCompanyData(state) {
    return state.company_address;
  },
};
const actions = {};
const mutations = {
  updateLoanID: (state, loan_id) => (state.guarantor_family.loan_id = loan_id),

  familyDetails: (state, responseData) => {
    if (responseData.name) {
      state.guarantor_family.name = responseData.name;
    }

    if (responseData.phone_number) {
      state.guarantor_family.phone_number = responseData.phone_number;
    }

    if (responseData.date_of_birth) {
      state.guarantor_family.date_of_birth = responseData.date_of_birth;
    }

    if (responseData.nrc) {
      state.guarantor_family.nrc_state_id = responseData.nrc.state_id;
      state.guarantor_family.nrc_state_number = responseData.nrc.state_number;
      state.guarantor_family.nrc_state_burmese_number =
        responseData.nrc.state_burmese_number;
      state.guarantor_family.nrc_district_id = responseData.nrc.district_id;
      state.guarantor_family.nrc_district_name = responseData.nrc.district_name;
      state.guarantor_family.nrc_district_burmese_name =
        responseData.nrc.district_burmese_name;
      state.guarantor_family.nrc_type_id = responseData.nrc.type_id;
      state.guarantor_family.nrc_type_name = responseData.nrc.type_name;
      state.guarantor_family.nrc_type_burmese_name =
        responseData.nrc.type_burmese_name;
    }

    if (responseData.nrc_number) {
      state.guarantor_family.nrc_number = responseData.nrc_number;
    }

    if (
      state.guarantor_family.nationality == "Myanmar" ||
      state.guarantor_family.nationality == null
    ) {
      state.guarantor_family.nationality = responseData.nationality;
      state.guarantor_family.other_nationality = null;
    } else {
      state.guarantor_family.nationality = responseData.nationality;
      state.guarantor_family.other_nationality = responseData.nationality;
    }

    state.guarantor_family.gender = responseData.gender;

    state.guarantor_family.marital_status = responseData.marital_status;

    // if (responseData.occupation == "") {
    //    state.guarantor_family.occupation = "UMG";
    // } else {
    // if (
    //    responseData.occupation != "Government Employee" ||
    //    responseData.occupation != "Own Business" ||
    //    responseData.occupation != "Company Employee" ||
    //    responseData.occupation != "UMG"
    // ) {
    //    state.guarantor_family.occupation = "Other";
    //    state.guarantor_family.occupation_details =
    //       responseData.occupation.occupation;
    // } else {
    //    state.guarantor_family.occupation =
    //       responseData.occupation.occupation;
    // }
    // state.guarantor_family.occupation = responseData.occupation.occupation;
    // }

    //    state.guarantor_family.occupation =
    //       responseData.occupation.occupation;

    state.guarantor_family.relationship_with_applicant =
      responseData.relationship_with_applicant;

    if (responseData.address != null) {
      if (responseData.address.country_state_id != null) {
        state.guarantor_family.country_state_id =
          responseData.address.country_state_id;
      }

      if (responseData.address.city_id != null) {
        state.guarantor_family.city_id = responseData.address.city_id;
      }

      if (responseData.address.township_id != null) {
        state.guarantor_family.township_id = responseData.address.township_id;
      }

      state.guarantor_family.place_type = responseData.address.place_type;

      state.guarantor_family.ward_or_village_name =
        responseData.address.ward_or_village_name;
      state.guarantor_family.street = responseData.address.street;
      state.guarantor_family.building_number =
        responseData.address.building_number;
      state.guarantor_family.floor = responseData.address.floor;

      state.guarantor_family.residence_type =
        responseData.address.residence_type;

      state.guarantor_family.living_with = responseData.address.living_with;

      state.guarantor_family.years_of_stay = responseData.address.years_of_stay;
      state.guarantor_family.months_of_stay =
        responseData.address.months_of_stay;
    }

    state.guarantor_family.company_name = responseData.occupation.company_name;
    state.guarantor_family.department_name =
      responseData.occupation.department_name;
    state.guarantor_family.position = responseData.occupation.position;
    state.guarantor_family.years_of_service =
      responseData.occupation.years_of_service;
    state.guarantor_family.months_of_service =
      responseData.occupation.months_of_service;
    state.guarantor_family.monthly_basic_income =
      responseData.occupation.monthly_basic_income;
    state.guarantor_family.additional_income =
      responseData.occupation.additional_income;
    state.guarantor_family.total_income = responseData.occupation.total_income;

    if (responseData.occupation.address != null) {
      if (responseData.occupation.address.country_state_id != null) {
        state.company_address.country_state_id =
          responseData.occupation.address.country_state_id;
      }

      if (responseData.occupation.address.city_id != null) {
        state.company_address.city_id = responseData.occupation.address.city_id;
      }

      if (responseData.occupation.address.township_id != null) {
        state.company_address.township_id =
          responseData.occupation.address.township_id;
      }

      state.company_address.place_type =
        responseData.occupation.address.place_type;

      state.company_address.ward_or_village_name =
        responseData.occupation.address.ward_or_village_name;
      state.company_address.street = responseData.occupation.address.street;
      state.company_address.building_number =
        responseData.occupation.address.building_number;
      state.company_address.floor = responseData.occupation.address.floor;
    }
  },

  addPersonalStateData: (state, payload) => {
    state.guarantor_family.nrc_state_id = payload.id;
    state.guarantor_family.nrc_state_burmese_number = payload.burmese_code;
    state.guarantor_family.nrc_state_number = payload.code;
  },
  addPersonalDistrictData: (state, payload) => {
    state.guarantor_family.nrc_district_id = payload.id;
    state.guarantor_family.nrc_district_burmese_name = payload.burmese_code;
    state.guarantor_family.nrc_district_name = payload.code;
  },
  addPersonalNRCtypeData: (state, payload) => {
    state.guarantor_family.nrc_type_id = payload.id;
    state.guarantor_family.nrc_type_burmese_name = payload.burmese_name;
    state.guarantor_family.nrc_type_name = payload.name;
  },

  addAddressRegionData: (state, payload) => {
    state.guarantor_family.country_state_id = payload.id;
  },
  addAddressCityData: (state, payload) => {
    state.guarantor_family.city_id = payload.id;
  },
  addAddressTownshipData: (state, payload) => {
    state.guarantor_family.township_id = payload.id;
  },

  addCompanyRegionData: (state, payload) => {
    state.company_address.country_state_id = payload.id;
  },
  addCompanyCityData: (state, payload) => {
    state.company_address.city_id = payload.id;
  },
  addCompanyTownshipData: (state, payload) => {
    state.company_address.township_id = payload.id;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
