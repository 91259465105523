import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";

import VueNumberFormat from "vue-number-format";

// router.beforeEach((to) => {
//    if (to.meta.requiresAuth && !store.getters["auth/isAuth"]) {
//       return {
//          path: "/",
//       };
//    }
// });

const app = createApp(App);
app.use(Quasar, quasarUserOptions);
app.use(store);
app.use(router);

app.use(VueNumberFormat, { prefix: "US$ ", decimal: ",", thousand: "." });

app.mount("#app");
